@import "vars";

body {
  background-color: $background-color;
  padding: 16px;
  font-family: -apple-system, helvetica, arial, sans-serif;
  font-size: 16px;
  color: $normal-text-color;
  line-height: $line-height;
  overflow-wrap: break-word;
}

#wrapper {
  max-width: 800px;
  margin: auto;
  > header {
    text-align: center;
  }
}

.clear-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.clear-list li {
  margin: 0;
  padding: 0;
}

.projects .namelink {
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1em;
}

.center {
  text-align: center;
}

header {
  margin-bottom: 18px;
}

hr {
  border: none;
  border-bottom: 1px solid #999;
}

a {
  text-decoration: underline;
  color: $link-color;
}  

a:visited {
  color: $visited-link-color;
}

a.nav-link,
a.post-title-link {
  color: $nav-link-color;
  text-decoration: none;
}

.post-title {
  line-height: $line-height;
  color: $nav-link-color;
  margin-bottom: 8px;
}

a.citation-link {
  text-decoration: none;
}

nav {
  text-align: center;
  padding: $nav-padd 0px;
}

nav a {
  margin: 1em;
  color: $nav-link-color;
  font-weight: bold;
  font-style: none;
}

nav a:hover,
a.post-title-link:hover {
  text-decoration: underline; 
}

li {
  margin: .5em;
}

table,
table tr,
table td,
table th {
  border: 1px solid #aaa;
  border-collapse: collapse;
  padding: 5px;
  font-weight: normal;
}
table th {
  font-weight: bold;
}
table {
  width: 75%;
  margin: auto;
}

img {
  display: block;
  width: 55%;
  margin-left: auto;
  margin-right: auto;
}

blockquote {
  font-style: italic;
}

address { 
  font-style: normal;
}

@media screen and (max-width: 600px){
  body {
    width: 90%;
  }
  nav {
    text-align: left;
    width: 100%;
  }
  nav a {
    display: block;
    text-align: left;
    padding-left: 0;
    margin-left: 0;
  }
}

.mono {
  font-family: monospace;
}

.bold {
  font-weight: bold;
}

figcaption {
  margin-top: 10px;
  font-style: italic;
}

footer {
  padding-top: 16px;
  margin-bottom: 100px;
}

.terminal,
.file {
  padding: 10px;
  overflow-x: scroll;
}

.terminal {
  line-height: $term-line-height;
  color: $terminal-fg;
  background-color: $terminal-bg;
}

.file {
  line-height: $file-line-height;
  background-color: $file-bg;
  color: $file-fg;
}

.small-image {
  width: 100%;
}

.post-date {
  text-transform: uppercase;
  font-weight: bold;
  color: $lighter-color;
}

.post-excerpt {
  margin-left: 24px;
}

.post-header {
  margin-bottom: 8px;
}
